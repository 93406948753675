import React, { useState, useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import { IMG } from "../const"
import "../assets/styles/commons.scss"
import "../assets/styles/commons/_loading-state.scss"
import "../assets/styles/portfolio-chapter.scss"
import PortfolioImagesContainer from "../components/PortfolioImagesContainer"
const FeedbackSection = loadable(() => import("../components/feedback-section"))

const PROJECTS_ORDER = [
  "The Spoke",
  "Cheqmate",
  "AgeLine",
  "House of Supporters",
  "Minutes Depot",
  "Call Listening",
  "Dope360",
  "Tag Warriors",
  "YouMe Social",
  "DataCabinet",
  "Euronavigator",
  "Wristo",
  "Servant",
]

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      portfolioJson {
        name
        projects {
          title
          description
          metaTitle
          tagList
          country
          flagIconUrl
          imageLink
          mainBgImage
          mainPortfolioImage
          pageList
          externalLink
          slug
          testimonial {
            avatar
            paragraph
            name
            job
          }
        }
      }
    }
  `)

  const [projectsList, setProjectsList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    let list = []

    PROJECTS_ORDER.forEach(item => {
      const existingProject = data.portfolioJson.projects.find(
        project => project.title === item
      )

      if (existingProject) {
        existingProject.pageList.map(
          page => page === "projects" && list.push(existingProject)
        )
      }
    })

    setProjectsList(list)
    setLoading(false)
  }, [data.portfolioJson.projects])

  return (
    <Layout
      pageTitle="Our recent case studies"
      metaRobotsContent="noindex, follow"
    >
      {() => {
        return (
          <>
            <main className="portfolioChapter">
              <article className="portfolioChapter__list">
                <div className="portfolioChapter__headline">
                  <h1>Projects</h1>
                  <p>
                    Learn more about people who inspire us to build such
                    powerful solutions for business
                  </p>
                </div>
                {projectsList.length <= 0 && loading && (
                  <section className="loadingState">
                    <div>
                      <p>LOADING</p>
                      <img
                        src={`${IMG.INDEX}/img/pages/loading.svg`}
                        alt="Loading"
                        width="112"
                        height="14"
                      />
                    </div>
                  </section>
                )}
                {!loading &&
                  projectsList.length > 0 &&
                  projectsList.map(item => (
                    <section
                      className="portfolioChapter__singleProjectSummary"
                      key={item.title}
                    >
                      <div className="portfolioChapter__singleProjectSummary__text">
                        <div className="portfolioChapter__singleProjectSummary__text__description">
                          <h2>
                            {item.externalLink ? (
                              <a
                                href={item.externalLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.title}
                              </a>
                            ) : item.slug ? (
                              <Link to={`/${item.slug}/`}>{item.title}</Link>
                            ) : (
                              item.title
                            )}
                          </h2>
                          <ul className="tags">
                            <li className="flag">
                              <img
                                src={`${IMG.INDEX}/${item.flagIconUrl}`}
                                width="22"
                                height="16"
                                alt={`${item.country} flag`}
                                title={`${item.country} flag`}
                                loading="lazy"
                              />
                            </li>
                            {item.tagList.map(tag => (
                              <li key={`tag_${tag.replace(/\s/g, "")}`}>
                                {tag}
                              </li>
                            ))}
                          </ul>
                          <div
                            className="portfolioChapter__singleProjectSummary__visual mobileOnly"
                            data-title={item.title}
                          >
                            <PortfolioImagesContainer item={item} />
                          </div>
                          <div className="info">
                            <p>{item.description}</p>
                          </div>
                          {item.externalLink && (
                            <a
                              href={item.externalLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="seeMore"
                            >
                              Discover project
                            </a>
                          )}
                          {item.slug && (
                            <Link to={`/${item.slug}/`} className="seeMore">
                              Discover project
                            </Link>
                          )}
                        </div>
                      </div>
                      <div
                        className="portfolioChapter__singleProjectSummary__visual"
                        data-title={item.title}
                      >
                        <PortfolioImagesContainer item={item} />
                      </div>
                      {item.testimonial && (
                        <div className="testimonial">
                          <div className="avatar">
                            <img
                              src={`${IMG.INDEX}/${item.testimonial.avatar}`}
                              width="64"
                              height="64"
                              alt={item.testimonial.name}
                              title={item.testimonial.name}
                              loading="lazy"
                            />
                          </div>
                          <div className="img">
                            <img
                              src={`${IMG.INDEX}/img/qou.svg`}
                              width="20"
                              height="20"
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="text">
                            <p>{item.testimonial.paragraph}</p>
                            <strong>{item.testimonial.name}</strong>
                            <p>{item.testimonial.job}</p>
                          </div>
                        </div>
                      )}
                    </section>
                  ))}
              </article>
            </main>
            {!loading && projectsList.length > 0 && <FeedbackSection />}
          </>
        )
      }}
    </Layout>
  )
}

export default Projects
